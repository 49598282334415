import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './Forecast.styles.js';
import { generateInstants, parseColor } from './Router.utils.js';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';

import VtkViewer from '../../../components/VtkViewer/VtkViewer.jsx';
import CardLoading from '../../../components/CardLoading/CardLoading.jsx';

function normalizeUrlPath(base, next){
	let base_chunks = base.split('/').filter(chunk => chunk.length > 0);
	let next_chunks = next.split('/').filter(chunk => chunk.length > 0);
	let chunks = [...base_chunks, ...next_chunks];
	return chunks.join('/');
}

function ColorBox({ receptor, disabledClass }){
	if('className' in receptor && 'text' in receptor){
		return (<Typography className={receptor.className}>{receptor.text}</Typography>);
	}
	else return (<Typography className={disabledClass}>-</Typography>);
};
ColorBox.propTypes = {
	receptor: PropTypes.object.isRequired,
	disabledClass: PropTypes.string.isRequired,
};

function PlanificationForecast({ planification })
{
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [instants, setInstants] = useState(0);
	const [dates, setDates] = useState(null);
	const [datesDays, setDatesDays] = useState(null);
	const [vectors, setVectors] = useState(null);
	const [vtkUrls, setVtkUrls] = useState(null);
	const [receptors, setReceptors] = useState([]);
	const [receptorsSource, setReceptorsSource] = useState([]);

	const [dataType, setDataType] = useState(null);
	const [currentInstant, setCurrentInstant] = useState(0);
	const [currentReceptors, setCurrentReceptors] = useState([]);

	useEffect(() => {
		// Calc Instants
		let vectores = planification.vectors;
		setInstants(vectores.length);

		// Parse Receptors
		const receptores = planification.receptors.filter(r => !r.source);
		const receptoresSource = planification.receptors.filter(r => r.source);
		setReceptors(receptores);
		setReceptorsSource(receptoresSource);

		const receptorsStorage = new Map;
		for(let recp of planification.receptors) receptorsStorage.set(recp.id, recp);

		// Parse date (to show in table)
		let dates = generateInstants(vectores);
		let dateDays = dates.filter(item => item.change);
		setDates(dates);
		setDatesDays(dateDays);

		// Get VTK urls
		let vtkList = [];
		for(let vector of vectores){
			// Parse date and urls
			vector.vtk.plume = '/' + normalizeUrlPath('vtk', vector.vtk.plume);
			vector.vtk.floor_mp = '/' + normalizeUrlPath('vtk', vector.vtk.floor_mp);
			vector.vtk.floor_no = '/' + normalizeUrlPath('vtk', vector.vtk.floor_no);
			vector.vtk.floor_so = '/' + normalizeUrlPath('vtk', vector.vtk.floor_so);

			// Add VTKs to list
			vtkList.push(vector.vtk);

			// Parse concentrations/receptors
			if(vector.concentrations.mp.length > 0){
				for(let concentration of vector.concentrations.mp){
					let recept = receptorsStorage.get(concentration.receptor_id);
					if(!recept) throw new Error('Receptor not found!');

					concentration.name = recept.label;
					concentration.text = (Math.round((concentration.value + Number.EPSILON) * 100) / 100).toString();
					concentration.color = parseColor(concentration.value, 'mp');
					if(concentration.color == 'red'){
						concentration.className = classes.receptorAlert;
					}
					else if(concentration.color == 'yellow'){
						concentration.className = classes.receptorWarning;
					}
					else if(concentration.color == 'green'){
						concentration.className = classes.receptorSuccess;
					}
				}
			}

			if(vector.concentrations.no.length > 0){
				for(let concentration of vector.concentrations.no){
					let recept = receptorsStorage.get(concentration.receptor_id);
					if(!recept) throw new Error('Receptor not found!');

					concentration.name = recept.label;
					concentration.text = (Math.round((concentration.value + Number.EPSILON) * 100) / 100).toString();
					concentration.color = parseColor(concentration.value, 'no');
					if(concentration.color == 'red'){
						concentration.className = classes.receptorAlert;
					}
					else if(concentration.color == 'yellow'){
						concentration.className = classes.receptorWarning;
					}
					else if(concentration.color == 'green'){
						concentration.className = classes.receptorSuccess;
					}
				}
			}

			if(vector.concentrations.so.length > 0){
				for(let concentration of vector.concentrations.so){
					let recept = receptorsStorage.get(concentration.receptor_id);
					if(!recept) throw new Error('Receptor not found!');

					concentration.name = recept.label;
					concentration.text = (Math.round((concentration.value + Number.EPSILON) * 100) / 100).toString();
					concentration.color = parseColor(concentration.value, 'so');
					if(concentration.color == 'red'){
						concentration.className = classes.receptorAlert;
					}
					else if(concentration.color == 'yellow'){
						concentration.className = classes.receptorWarning;
					}
					else if(concentration.color == 'green'){
						concentration.className = classes.receptorSuccess;
					}
				}
			}
		};
		setVtkUrls(vtkList);

		// Vectors
		setVectors(vectores);

		// Set DataType
		setDataType('mp');

		setLoading(false);
	}, [planification]);

	useEffect(() => {
		if(dataType == null) return;
		setCurrentReceptors(vectors[currentInstant].concentrations[dataType]);
	}, [vectors, dataType, currentInstant]);

	const plumeValue = (value) => {
		if(value){
			return (
				<Typography className={classes.receptorWarning}><CloudQueueIcon fontSize="small"/></Typography>
			);
		}
		else{
			return (
				<Typography className={classes.receptorSuccess}><CloudOffIcon fontSize="small"/></Typography>
			);
		}
	};

	const handleInstantChange = (newValue) => {
		if(newValue >= 0 && newValue < instants) setCurrentInstant(newValue);
	};

	const handleChangeDataType = (newValue) => {
		setDataType(newValue);
	};

	if (loading){
		return (<CardLoading text="Cargando Pronóstico..."></CardLoading>);
	}

	return (
		<Card>
			<CardContent>
				<VtkViewer
					urls={vtkUrls}
					value={currentInstant}
					receptors={currentReceptors}
					onChangeInstant={handleInstantChange}
					dataType={dataType}
					onChangeDataType={handleChangeDataType}
				/>
			</CardContent>
			<Divider />
			<TableContainer component={CardContent} className={classes.tableCard}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							{datesDays.map((item, index) => (
								<TableCell key={index} colSpan={item.amount} className={classes.dateCell}>{item.text_date}</TableCell>
							))}
							<TableCell className={classes.lastCell}></TableCell>
						</TableRow>
						<TableRow>
							<TableCell className={classes.cellAlignVertical}>Receptor</TableCell>
							{dates.map((item, index) => (
								<TableCell key={index} align="center" className={clsx(classes.tableHeaderCell, (currentInstant === index) && classes.cellSelected)}>
									<Typography className={classes.cellVertical} component="span">
										{item.text_time}
									</Typography>
								</TableCell>
							))}
							<TableCell className={classes.lastCell}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{receptorsSource.map((receptor, r_index) => (
							<TableRow key={r_index}>
								<TableCell component="th" scope="row" className={classes.colSticky}>
									<Typography className={classes.receptorName} noWrap>{receptor.label}</Typography>
								</TableCell>
								{vectors.map((vector, index) => (
									<TableCell key={index} align="center" className={clsx(classes.tableBodyCell, (currentInstant === index) && classes.cellSelected)}>
										{plumeValue(vector.concentrations[dataType].find(con => (con.receptor_id === receptor.id)))}
									</TableCell>
								))}
								<TableCell className={classes.lastCell}></TableCell>
							</TableRow>
						))}
						{receptors.map((receptor, r_index) => (
							<TableRow key={r_index}>
								<TableCell component="th" scope="row" className={classes.colSticky}>
									<Typography className={classes.receptorName} noWrap>{receptor.label}</Typography>
								</TableCell>
								{vectors.map((vector, index) => (
									<TableCell key={index} align="center" className={clsx(classes.tableBodyCell, (currentInstant === index) && classes.cellSelected)}>
										<ColorBox
											receptor={vector.concentrations[dataType].find(con => (con.receptor_id === receptor.id))}
											disabledClass={classes.receptorDisabled}
										/>
									</TableCell>
								))}
								<TableCell className={classes.lastCell}></TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Card>
	);
}

PlanificationForecast.propTypes = {
	planification: PropTypes.object.isRequired,
};

export default PlanificationForecast;