import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useFetch, useFetchDoneEffect } from '../../../hooks/fetch.js';
import moment from '../../../utils/moment.js';
import { Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { assignAmounts } from './Router.utils.js';
import { useSocket } from '../../../contexts/WebSocketContext.js';

import TitledRoute from '../../../components/Router/TitledRoute.jsx';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardLoading from '../../../components/CardLoading/CardLoading.jsx';
import Divider from '@material-ui/core/Divider';
import PlanificacionHeader from  '../../../components/Planification/ShowHeader.jsx';
import Card from '@material-ui/core/Card';

import Detail from './Detail.jsx';
import Forecast from './Forecast.jsx';
import Statistics from './Statistics.jsx';

const CustomTabs = withStyles(theme => ({
	root: {
        marginLeft: theme.spacing(1),
	},
}))(Tabs);

const CustomTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(4),
		'&:hover': {
			opacity: 1,
		},
		'&$selected': {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightMedium,
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(2),
	},
	rootSection: {
		padding: theme.spacing(2),
		margin: '0 auto',
		[theme.breakpoints.up('lg')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
}));

const compareVectorDates = (vect1, vect2) => {
    return vect1.span - vect2.span;
}

function ShowRouter(){
    const { planificationId } = useParams();
    const { path, url } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        if(location.pathname.endsWith('detail')){
            setTabValue(0);
        }
        else if(location.pathname.endsWith('forecast')){
            setTabValue(1);
        }
        else if(location.pathname.endsWith('statistics')){
            setTabValue(2);
        }
    }, []);

    const handleTabChange = (_, newValue) => {
        if(newValue == tabValue) return;
        setTabValue(newValue);
        if(newValue == 0){
            history.push(`${url}/detail`);
        }
        else if(newValue == 1){
            history.push(`${url}/forecast`);
        }
        else if(newValue == 2){
            history.push(`${url}/statistics`);
        }
    };

    const [loadingPlanification, setLoadingPlanification] = useState(true);
	const [forecastDisabled, setForecastDisabled] = useState(true);
	const [planification, setPlanification] = useState(null);

    const planificationResult = useFetch(`planifications/${planificationId}/`, {
		parser(plan){
			plan.start_at = moment(plan.start_date).format('lll');
			plan.end_at = moment(plan.end_date).format('lll');

			// Parse Vectors
			let lastDate = null;
			for(let vector of plan.vectors){
				let momentDate = moment(vector.span);
				vector.text_date = momentDate.format('ll');
				vector.text_time = momentDate.format('HH:mm');
				vector.short_date = momentDate.format('DD MMM HH:mm');

				vector.original_span = vector.span;
				let currentDate = new Date(vector.span);
				if(lastDate == null || (lastDate.getDay() != currentDate.getDay())){
					vector.change = true;
				}
				else{
					vector.change = false;
				}
				vector.span = currentDate;
				lastDate = currentDate;
			}
            plan.vectors.sort(compareVectorDates);
			assignAmounts(plan.vectors);

			// Process Videos
			setForecastDisabled(plan.videos.mp == null && plan.videos.no == null && plan.videos.so == null);

			return plan;
		}
	});

	useFetchDoneEffect((data) => {
		setPlanification(data);
		setLoadingPlanification(false);
	}, planificationResult);

    const socket = useSocket();

	const handleSocketMessage = event => {
		try{
			let result = JSON.parse(event.data);
			if(!('data' in result)) throw new Error('Property "data" not found is message!');

            if(planification != null){
                let plan = result.data.find(p => (p.id == planification.id));
                if(!plan) return;

                // Update data
                if(plan.job_state != planification.job_state){
                    //console.debug(plan.job_state, ' => ', planification.job_state, plan);
                    planification.job_state = plan.job_state;
                    setPlanification({ ...planification });
                }
            }
		}
		catch(err){
			console.error(err);
		}
	};

	useEffect(() => {
		if(socket && socket.instance != null) socket.on('message', handleSocketMessage);
		return () => {
			if(socket && socket.instance != null) socket.off('message', handleSocketMessage);
		};
	}, []);

    if(loadingPlanification || planification == null){
        return (
			<section className={classes.rootSection}>
				<CardLoading text="Cargando Planificacion..." />
			</section>);
    }

    return (
        <section className={classes.rootSection}>
            <Card className={classes.root}>
                <PlanificacionHeader
                    id={planificationId}
                    start_at={planification.start_at}
                    end_at={planification.end_at}
                    original={planification.original}
                    videos={planification.videos}
					videosDisabled={forecastDisabled}
                />
                <Divider />
                <CustomTabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-label="Planification Tabs"
                >
                    <CustomTab label="Detalle" />
                    <CustomTab label="Pronóstico" disabled={forecastDisabled} />
                    <CustomTab label="Estadísticas" />
                </CustomTabs>
            </Card>
            <Switch>
                <TitledRoute path={`${path}/detail`} exact title="Ver Detalle">
                    <Detail planification={planification} />
                </TitledRoute>
                <TitledRoute path={`${path}/forecast`} exact title="Ver Pronóstico">
                    <Forecast planification={planification} />
                </TitledRoute>
                <TitledRoute path={`${path}/statistics`} exact title="Ver Estadísticas">
                    <Statistics planification={planification} />
                </TitledRoute>
            </Switch>
        </section>
    );
};

export default ShowRouter;