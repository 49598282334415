import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import AccountBox from '@material-ui/icons/AccountBox';
import MoreIcon from '@material-ui/icons/MoreVert';
import HomeIcon from '@material-ui/icons/Home';
import ViewListIcon from '@material-ui/icons/ViewList';
import BurstModeIcon from '@material-ui/icons/BurstMode';

import logo from '../../assets/logo-white.svg';
import useStyles from './Layout.styles.js';

import { useSession } from '../../contexts/SessionContext.js';

const menuId = 'primary-search-account-menu';
const mobileMenuId = 'primary-search-account-menu-mobile';
export default function Layout(props) {
	const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const [drawerOpen, setDrawerOpen] = React.useState(false);
	const { logout } = useSession();

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleOpenPasswordConfig = () => {
		//history.push('/profile');
	}

	const handleCloseSession = () => {
		logout();
		handleMenuClose();
		history.push('/');
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={handleOpenPasswordConfig} disabled>
				<ListItemIcon className={classes.menuIcon}>
					<AccountBox fontSize="small" />
				</ListItemIcon>
				<ListItemText primary="Cambiar Contrase&ntilde;a" />
			</MenuItem>
			<MenuItem onClick={handleCloseSession}>
				<ListItemIcon className={classes.menuIcon}>
					<ExitToApp fontSize="small" />
				</ListItemIcon>
				<ListItemText primary="Cerrar Sesi&oacute;n" />
			</MenuItem>
		</Menu>
	);

	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem
				onClick={handleOpenPasswordConfig}
				disabled
				aria-label="account of current user"
				aria-controls="primary-search-account-menu"
				aria-haspopup="true"
			>
				<ListItemIcon className={classes.menuIcon}>
					<AccountBox />
				</ListItemIcon>
				<ListItemText primary="Cambiar Contrase&ntilde;a" />
			</MenuItem>
			<MenuItem
				onClick={handleCloseSession}
				aria-label="close the current session"
				aria-controls="primary-search-account-menu"
				aria-haspopup="true"
			>
				<ListItemIcon
					className={classes.menuIcon}
				>
					<ExitToApp />
				</ListItemIcon>
				<ListItemText primary="Cerrar Sesi&oacute;n" />
			</MenuItem>
		</Menu>
	);

	const toggleDrawer = () => {
		setDrawerOpen(state => !state);
	};

	return (
		<React.Fragment>
			<AppBar className={classes.appBar} position="fixed" elevation={0}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer}
					>
						<MenuIcon />
					</IconButton>
					<div className={classes.appbarLogo}>
						<img src={logo} draggable="false" width="100" />
					</div>
					<div className={classes.navMenu}>
						<Button
							className={clsx(classes.navMenuButton, (location.pathname == '/' && classes.navMenuButtonSelected))}
							color="inherit"
							onClick={() => history.push('/home')}
						>
							Home
						</Button>
						<Button
							className={clsx(classes.navMenuButton, (location.pathname == '/planifications' && classes.navMenuButtonSelected))}
							color="inherit"
							onClick={() => history.push('/planifications')}
						>
							Planificaciones
						</Button>
						<Button
							className={clsx(classes.navMenuButton, (location.pathname == '/timelapse' && classes.navMenuButtonSelected))}
							color="inherit"
							onClick={() => history.push('/timelapse')}
						>
							Timelapse
						</Button>
					</div>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<Select
							MenuProps={{
								anchorOrigin: {
									vertical: "top",
									horizontal: "right"
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "right"
								},
							}}
							className={classes.selectLocation}
							variant="outlined"
							value={1}
						>
							<MenuItem value={1}>Planta Pellets</MenuItem>
						</Select>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
			<SwipeableDrawer
				open={drawerOpen}
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper,
				}}
				variant="persistent"
				anchor="left"
				onClose={toggleDrawer}
				onOpen={toggleDrawer}
			>
				<List>
					<ListItem button key="Home" onClick={() => history.push('/home')}>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText primary="Home" />
					</ListItem>
					<ListItem button key="Planificaciones" onClick={() => history.push('/planifications')}>
						<ListItemIcon>
							<ViewListIcon />
						</ListItemIcon>
						<ListItemText primary="Planificaciones" />
					</ListItem>
					<ListItem button key="Timelapse" onClick={() => history.push('/timelapse')}>
						<ListItemIcon>
							<BurstModeIcon />
						</ListItemIcon>
						<ListItemText primary="Timelapse" />
					</ListItem>
				</List>
			</SwipeableDrawer>
			<div className={classes.layoutContainer}>{props.children}</div>
		</React.Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node ]).isRequired
};