import React, { useState, useMemo, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const API_VERSION = 1;
const BASE_URL = window.location.origin + '/api/v' + API_VERSION + '/';

const DEFAULT_AXIOS = axios.create({
	baseURL: BASE_URL,
	headers: {
		common: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		}
	},
});

class SessionRequest {
	constructor(){
		this.enqueueSnackbar = null;
		this.request = null;
	}

	parseResponse(response){
		if('data' in response && typeof(response.data) == 'object' && 'message' in response.data && 'content' in response.data){
			let data = response.data;
			if(this.enqueueSnackbar != null){
				if(data.message instanceof Array){
					for(let msg of data.message){
						this.enqueueSnackbar(msg.text, { variant: msg.type });
					}
				}
				else if(typeof(data.message) == 'object' && data.message != null){
					this.enqueueSnackbar(data.message.text, { variant: data.message.type });
				}
			}
			return data.content;
		}
		return response;
	}

	options(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.options(...args).then(this.parseResponse.bind(this));
	}

	get(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.get(...args).then(this.parseResponse.bind(this));
	}

	post(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.post(...args).then(this.parseResponse.bind(this));
	}

	put(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.put(...args).then(this.parseResponse.bind(this));
	}

	patch(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.patch(...args).then(this.parseResponse.bind(this));
	}

	delete(...args){
		if(this.request == null)
			return Promise.reject('(SessionRequest) Property "request" is not defined yet!');
		return this.request.delete(...args).then(this.parseResponse.bind(this));
	}
}

const createAxiosInstance = (token) => DEFAULT_AXIOS.create({
	headers: {
		common: { 'Authorization': `Token ${token}` }
	},
});

const createSession = (token) => {
	let date = new Date;
	date.setDate(date.getDate() + 1);

	localStorage.setItem('session.expiration', date);
	localStorage.setItem('session.token', token);

	let request = createAxiosInstance(token);
	let session = {
		isAuthenticated: true,
		token,
		request: request,
	};

	return { session, request };
};

const sessionRequest = new SessionRequest;

const createEmptySession = (token) => {
	localStorage.removeItem('session.expiration');
	localStorage.removeItem('session.token');

	return {
		request: DEFAULT_AXIOS,
		session: {
			isAuthenticated: false,
			expiration: null,
			token: null,
		},
	}
}

const createSessionContext = () => {
	let data = {
		api: sessionRequest,
		request: DEFAULT_AXIOS,
		session: {
			isAuthenticated: false,
			expiration: null,
			token: null,
		},
		login: () => {},
		logout: () => {},
	};

	if(!localStorage.hasOwnProperty('session.token')) return data;
	if(!localStorage.hasOwnProperty('session.expiration')){
		localStorage.removeItem('session.token');
	}

	let token = localStorage.getItem('session.token');
	if(!token){
		localStorage.removeItem('session.token');
		localStorage.removeItem('session.expiration');
		return data;
	}

	let now = new Date;
	let expiration = new Date(localStorage.getItem('session.expiration'));
	if(now > expiration){
		localStorage.removeItem('session.token');
		localStorage.removeItem('session.expiration');
		return data;
	}

	data.request = createAxiosInstance(token);
	data.session.token = token;
	data.session.expiration = expiration;
	data.session.isAuthenticated = true;
	return data;
};

const initialContextData = createSessionContext();

const SessionContext = createContext(initialContextData);

export const useSession = () => useContext(SessionContext);

export function SessionProvider({ children }){
	const { enqueueSnackbar } = useSnackbar();
	const [context, setContext] = useState(initialContextData);
	const sessionValue = useMemo(
		() => {
			sessionRequest.enqueueSnackbar = enqueueSnackbar;
			sessionRequest.request = context.request;
			return {
				...context,
				api: sessionRequest,
				login(token){
					let { session, request } = createSession(token);
					setContext(ctx => ({ ...ctx, session, request }));
				},
				logout(){
					let { session, request } = createEmptySession();
					setContext(ctx => ({ ...ctx, session, request }));
				}
			}
		}, 
		[context]
	);

	return (
		<SessionContext.Provider value={sessionValue}>
			{children}
		</SessionContext.Provider>
	);
};
SessionProvider.propTypes = {
	children: PropTypes.any.isRequired,
};