export function zeropad(value, size = -3){
	return ('00000' + value).substr(size);
};

export function generateInstants(vectors){
	let dates = vectors.map(vector => ({
		date: vector.span,
		text_date: moment(vector.span).format('ll'),
		text_time: moment(vector.span).format('HH:mm'),
		change: false,
		amount: 0,
	}));

	let lastDate = null;
	let criticalPoints = [];
	dates.forEach((date, index) => {
		if(index == 0 || (lastDate.getDay() != date.date.getDay())){
			criticalPoints.push(index);
			dates[index].change = true;
		}
		lastDate = date.date;
	});

	criticalPoints.forEach((point, index, list) => {
		let next = index + 1;
		if(next < list.length){
			dates[point].amount = list[next] - point;
		}
		else{
			dates[point].amount = dates.length - point;
		}
	});

	return dates;
};

export function checkVectores(vectors){
	for(let vector of vectors){
		if(vector.receptors.length == 0) return false;
	}
	return true;
}
