import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { TimelapseProvider } from '../../contexts/TimelapseContext.js';
import TitledRoute from '../../components/Router/TitledRoute.jsx';
import Home from '../Home/Home.jsx';
import Planifications from '../Planification/List.jsx';
import PlanificationRouter from '../Planification/Router.jsx';
import UserProfile from '../UserProfile/UserProfile.jsx';
import Timelapse from '../Timelapse/Timelapse.jsx';
import Layout from '../../components/Layout/Layout.jsx';

function Main(){
    return (
		<Layout>
            <Switch>
                <TitledRoute path="/home" exact title="Home">
                    <Home />
                </TitledRoute>
                <TitledRoute path="/profile" exact title="UserProfile">
                    <UserProfile />
                </TitledRoute>
                <TitledRoute path="/timelapse" exact title="Timelapse">
                    <TimelapseProvider>
                        <Timelapse />
                    </TimelapseProvider>
                </TitledRoute>
                <TitledRoute path="/planifications" exact title="Planificaciones">
                    <Planifications />
                </TitledRoute>
                <Route path="/planification">
                    <PlanificationRouter />
                </Route>
            </Switch>
        </Layout>
    )
};

export default Main;