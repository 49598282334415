import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from '../utils/moment.js';
import { useSession } from "./SessionContext.js";
import { useSnackbar } from "notistack";

let fetcher = axios.create({
	baseURL: window.location.origin + '/timelapse-proxy/',
});

const initContextValue = {
    camerasLoading: true,
    cameras: [],
    images: {},
};

function compareDates(imgA, imgB) {
	return imgA.timestamp_device - imgB.timestamp_device;
}

function compareDates2(imgA, imgB) {
	return imgA.timestamp_created_at - imgB.timestamp_created_at;
}

function processImages(images) {
	for(let img of images){
		img.timestamp_device = new Date(img.timestamp_device);
		img.timestamp_host = new Date(img.timestamp_host);
	}
	return images.sort(compareDates);
}

function parseVideos(videos) {
	for(let video of videos){
		video.timestamp_created_at = new Date(video.timestamp_created_at);
		video.timestamp_end_date = new Date(video.timestamp_end_date);
		video.timestamp_start_date = new Date(video.timestamp_start_date);

		video.startTime = moment(video.timestamp_start_date).format('HH:mm');
		video.endTime = moment(video.timestamp_end_date).format('HH:mm');

		video.original_url = video.video_file;
		if(video.video_file.startsWith('http://staging.tl.matcom.cl/')){
			video.video_file = video.video_file.replace('http://staging.tl.matcom.cl/', '');
		}
		else if(video.video_file.startsWith('https://staging.tl.matcom.cl/')){
			video.video_file = video.video_file.replace('https://staging.tl.matcom.cl/', '');
		}
	}
	return videos.sort(compareDates2);
}

const date2str = (date) => moment(date).format('YYYY-MM-DD+HH:mm:ss');
const date2iso = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss');

function downloadFromUrl(url, fileName=null){
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('target', '_blank');
	if(fileName != null) link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
};

const parseAxiosResponse = (res) => {
	if(res.status == 200) return res.data;
	return { error: 'no_data' };
};

const imageStorage = new Map;
const videosStorage = new Map;

const TimelapseContext = createContext(initContextValue);

export const useTimelapse = () => useContext(TimelapseContext);

export function TimelapseProvider({ children }){
	const [abortController, setAbortController] = useState(null);
    const [camerasLoading, setCamerasLoading] = useState(true);
    const [cameras, setCameras] = useState([]);
    const [framesLoaded, setFramesLoaded] = useState(false);
    const [framesLoading, setFramesLoading] = useState(false);
    const [frames, setFrames] = useState([]);
    const [videosLoading, setVideosLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [makingVideo, setMakingVideo] = useState(false);
    const [taskData, setTaskData] = useState(null);
	const { session } = useSession();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if(session.isAuthenticated){
			fetcher = fetcher.create({
				headers: {
					common: { 'Authorization': `Token ${session.token}` }
				},
			});
		}
	}, [session]);

	useEffect(() => {
		if(frames.length > 0){
			let loaded = true;
			for(let frame of frames){
				if(frame.loading){
					loaded = false;
					break;
				}
			}
			setFramesLoaded(loaded);
		}
	}, [frames]);

	const createImage = (data, controller) => {
		let imageUrl = data.image_file.replace('http://staging.tl.matcom.cl/', '');
		let thumbnailUrl = data.thumbnail_file.replace('http://staging.tl.matcom.cl/', '');
		let instance = new Image;
		let time = moment(data.timestamp_device).format('HH:mm');

		let image = {
			id: data.id,
			imageUrl,
			thumbnailUrl,
			error: false,
			loading: true,
			instance,
			date: data.timestamp_device,
			time,
		};
		imageStorage.set(image.id, image);

		instance.onload = () => {
			let newImage = {
				...image,
				error: false,
				loading: false,
			}
			imageStorage.set(newImage.id, newImage);
			setFrames(frames => frames.map(frame => ((frame == image)? newImage: frame)));
		};

		instance.onerror = () => {
			let newImage = {
				...image,
				error: true,
				loading: false,
			}
			imageStorage.set(newImage.id, newImage);
			setFrames(frames => frames.map(frame => ((frame == image)? newImage: frame)));
		};

		fetcher.get(imageUrl, {
			responseType: 'arraybuffer',
			signal: controller.signal,
			// TODO: Not working because response doesnt have a "Content-Length"!
			// onDownloadProgress: progressEvent => {
			// 	const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
			// 	const current = progressEvent.currentTarget.response.length

			// 	let percentCompleted = Math.floor(current / total * 100)
			// 	console.log('progress:', percentCompleted);
			// }
		})
		.then(response => Buffer.from(response.data, 'binary'))
		.then(buffer => {
			let blob = new Blob([buffer], { type: "image/jpeg" });
			let url = URL.createObjectURL(blob);
			instance.src = url;
		});

		return image;
	};

	const fetchImages = (startDate, endDate, cameraId, controller) => {
		setFramesLoading(true);
		let url = `api/snapshot/display/?timestamp_device__gte=${startDate}&timestamp_device__lte=${endDate}&search=${cameraId}`;
		fetcher.get(url, { signal: controller.signal }).then(parseAxiosResponse).then(data => {
			if(!Array.isArray(data)) return;

			const imagesSet = processImages(data);
			let frames = [];
			for(let img of imagesSet){
				let image;
				if(imageStorage.has(img.id)){
					image = imageStorage.get(img.id);
				}
				else{
					image = createImage(img, controller);
				}
				frames.push(image);
			}

			setFrames(frames);
			setFramesLoading(false);
		}).catch(() => {});
	};

	const [currentVideos, setCurrentVideos] = useState([]);

	const fetchVideos = (startDate, endDate, cameraId, controller=null) => {
		setVideosLoading(true);
		let url = `api/video/display/?timestamp_start_date__gte=${startDate}&timestamp_end_date__lte=${endDate}&search=${cameraId}`;
		let options = (controller != null)?({ signal: controller.signal }):({});
		fetcher.get(url, options).then(parseAxiosResponse).then(data => {
			if(!Array.isArray(data)) return;

			const videosList = parseVideos(data);
			let videos = [];
			for(let vid of videosList){
				if(videosStorage.has(vid.id)){
					let video = videosStorage.get(vid.id);
					videos.push(video);
				}
				else{
					videos.push(vid);
				}
			}

			setVideos(videos);
			setVideosLoading(false);
		}).catch(() => {});
	};

	const createTask = (task_id) => {
		setTaskData({
			task_id,
			progress: 0,
		});
		setMakingVideo(true);
	};

	useEffect(() => {
		if(makingVideo && taskData != null){
			let timer = setTimeout(() => {
				fetcher.get('api/celery-progress/' + taskData.task_id)
				.then(parseAxiosResponse).then(data => {
					if(data.complete){
						if(data.success){
							setMakingVideo(false);
							setTaskData(null);
							if(Array.isArray(currentVideos) && currentVideos.length > 0) fetchVideos(...currentVideos);
							downloadFromUrl('https://staging.tl.matcom.cl' + data.result);
						}
						else{
							setMakingVideo(false);
							setTaskData(null);
							enqueueSnackbar('Ocurrió un error al intentar crear el video!', { variant: 'error' });
						}
					}
					else{
						setTaskData(task => ({
							...task,
							progress: Math.round(data.progress.percent),
						}));
					}
				}).catch(err => {
					setMakingVideo(false);
					setTaskData(null);
					console.error(err);
					enqueueSnackbar('Ocurrió un error al intentar crear el video!', { variant: 'error' });
				});
			}, 200);
		}
	}, [makingVideo, taskData, currentVideos]);

	const timelapseValue = useMemo(
		() => {
			return {
				cameras,
				camerasLoading,
				frames,
				framesLoading,
				framesLoaded,
				videos,
				videosLoading,
				makingVideo,
				taskData,
				fetchFrames(startDate, endDate, cameraId){
					if(session.isAuthenticated){
						if(abortController != null) abortController.abort();
						let controller = new AbortController;
						setAbortController(controller);

						startDate = date2str(startDate);
						endDate = date2str(endDate);

						fetchImages(startDate, endDate, cameraId, controller);

						setCurrentVideos([startDate, endDate, cameraId]);
						fetchVideos(startDate, endDate, cameraId, controller);
					}
				},
				downloadVideo(video){
					let start = video.startTime.replace(':', '');
					let end = video.endTime.replace(':', '');
					const file_name = `timelapse-${video.id}_${start}-${end}.webm`;

					// TODO: Custom Toast Notification to download.
					// fetcher({
					// 	url: video.video_file,
					// 	method: 'GET',
					// 	responseType: 'blob',
					// }).then((response) => {
					// 	const blob = new Blob([response.data]);
					// 	const url = window.URL.createObjectURL(blob);
					// 	const link = document.createElement('a');
					// 	link.href = url;
					// 	link.setAttribute('download', file_name);
					// 	document.body.appendChild(link);
					// 	link.click();
					// });

					const link = document.createElement('a');
					link.href = video.original_url;
					link.setAttribute('target', '_blank');
					link.setAttribute('download', file_name);
					document.body.appendChild(link);
					link.click();
				},
				createVideo(startDate, endDate, cameraId){
					let start = date2iso(startDate);
					let end = date2iso(endDate);

					let form = new FormData();
					form.append('timestamp_start_date', start);
					form.append('timestamp_end_date', end);
					form.append('camera_id', cameraId);

					fetcher({
						method: 'POST',
						url: 'api/video/generate/',
						data: form,
						headers: { "Content-Type": "multipart/form-data" },
					}).then(res => {
						if(res.status == 200){
							return res.data;
						}
						else{
							return { error: 'no_data' };
						}
					}).then(data => {
						if(!Array.isArray(data)) return;
						let [task_id] = data;
						createTask(task_id);
					});
				},
			}
		}, 
		[
			session,
			cameras,
			camerasLoading,
			framesLoading,
			frames,
			framesLoaded,
			videos,
			videosLoading,
			makingVideo,
			taskData,
		]
	);

    useEffect(() => {
		if(session.isAuthenticated){
			setCamerasLoading(true);
			fetcher.get('api/camera/display').then(res => {
				if(res.status == 200){
					return res.data;
				}
				else{
					return { error: 'no_data' };
				}
			}).then(data => {
				if(!Array.isArray(data)) return;
				setCameras(data);
				setCamerasLoading(false);
			}).catch(err => console.error(err));
		}
    }, [session]);

	return (
		<TimelapseContext.Provider value={timelapseValue}>
			{children}
		</TimelapseContext.Provider>
	);
};
TimelapseProvider.propTypes = {
	children: PropTypes.any.isRequired,
};