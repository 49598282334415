import React, { useState, useEffect } from 'react';
import { useTimelapse } from '../../contexts/TimelapseContext.js';
import useStyles from './Timelapse.styles.js';
import { withStyles } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DatePicker from '../../components/Timelapse/DatePicker.jsx';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import TimelapseViewer from '../../components/Timelapse/TimelapseViewer.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import CardLoading from '../../components/CardLoading/CardLoading.jsx';
import ListVideos from '../../components/Timelapse/ListVideos.jsx';

const setCustomTime = (date, hour, minute) => {
    let copy = new Date(date.getTime());
    copy.setHours(hour);
    copy.setMinutes(minute);
    copy.setSeconds(0);
    copy.setMilliseconds(0);
    return copy;
};

const today = setCustomTime(new Date, 0, 0);

const instants = [
    {
        label: '00:00 - 05:55',
        start: date => setCustomTime(date, 0, 0),
        end: date => setCustomTime(date, 5, 55),
    },
    {
        label: '06:00 - 11:55',
        start: date => setCustomTime(date, 6, 0),
        end: date => setCustomTime(date, 11, 55),
    },
    {
        label: '12:00 - 17:55',
        start: date => setCustomTime(date, 12, 0),
        end: date => setCustomTime(date, 17, 55),
    },
    {
        label: '18:00 - 23:55',
        start: date => setCustomTime(date, 18, 0),
        end: date => setCustomTime(date, 23, 55),
    },
];

const CustomSelectInput = withStyles((theme) => ({
    root: {
        '& .MuiSelect-icon': {
            color: 'white',
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        fontSize: '0.8125rem',
        padding: '6px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            backgroundColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

function Timelapse() {
    const [currentDate, setCurrentDate] = useState(today);
    const [currentCamera, setCurrentCamera] = useState(0);
    const [currentInstant, setCurrentInstant] = useState(0);
    const [currentFrame, setCurrentFrame] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogStartDate, setDialogStartDate] = useState(null);
    const [dialogEndDate, setDialogEndDate] = useState(null);
    const {
        camerasLoading,
        cameras,
        framesLoading,
        frames,
        framesLoaded,
        videos,
        videosLoading,
        makingVideo,
        taskData,
        fetchFrames,
        downloadVideo,
        createVideo,
    } = useTimelapse();
    const classes = useStyles();

    useEffect(() => {
        if(!!frames && frames.length > 0){
            let first = frames[0];
            let last = frames[frames.length - 1];
            setDialogStartDate(first.date);
            setDialogEndDate(last.date);
        }
    }, [frames]);

    const refreshFrames = (date, instant, camera) => {
        let startDate = instant.start(date);
        let endDate = instant.end(date);
        setCurrentFrame(0);
        fetchFrames(startDate, endDate, camera.id);
    }

    const onChangeInstant = (event) => {
        let newInstant = event.target.value;
        if(newInstant > 0 && newInstant <= instants.length){
            setCurrentInstant(newInstant);
            newInstant = newInstant - 1;
            let instant = instants[newInstant];
            let camera = cameras[currentCamera];
            refreshFrames(currentDate, instant, camera);
        }
    }

    const onChangeCamera = event => {
        let newCamera = event.target.value;
        setCurrentCamera(newCamera);
        let instant = instants[currentInstant - 1];
        let camera = cameras[newCamera];
        refreshFrames(currentDate, instant, camera);
    };

    const handleFrameChange = (frameIndex) => {
        if(frameIndex >= 0 && frameIndex < frames.length){
            setCurrentFrame(frameIndex);
        }
    };

    const handleChangeDate = (date) => {
        setCurrentDate(date);
        let instant = instants[currentInstant - 1];
        let camera = cameras[currentCamera];
        refreshFrames(date, instant, camera);
    };

    const handleCreateVideo = () => {
        if(!camerasLoading && !framesLoading && currentInstant != 0){
            setDialogOpen(true);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogOk = () => {
        let camera = cameras[currentCamera];
        createVideo(dialogStartDate, dialogEndDate, camera.id);
        setDialogOpen(false);
    };

    const handleDialogStartDateChange = (event) => {
        let date = event.target.value;
        setDialogStartDate(date);
    };

    const handleDialogEndDateChange = (event) => {
        setDialogEndDate(event.target.value);
    };

    return (
        <section className={classes.root}>
            <Card className={classes.viewerCard}>
                <CardContent>
                    <div className={classes.buttons}>
                        <div className={classes.buttonItem}>
                            <DatePicker
                                onChange={handleChangeDate}
                                date={currentDate}
                            />
                        </div>
                        <div className={classes.buttonItem}>
                            {(camerasLoading)?(
                                <Button
                                    size="small"
                                    variant="contained"
                                    disableElevation
                                    disabled
                                    color="primary"
                                >
                                    Cargando...
                                </Button>
                            ):(
                                <FormControl className={classes.formControl}>
                                    <Select
                                        disabled={camerasLoading || framesLoading}
                                        labelId="instant-select-label"
                                        id="instant-select"
                                        defaultValue={0}
                                        value={currentCamera}
                                        onChange={onChangeCamera}
                                        input={<CustomSelectInput />}
                                    >
                                        {cameras.map((item, index) => (
                                            <MenuItem key={item.id} value={index}>{ item.name }</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                        <div className={classes.buttonItem}>
                            <FormControl className={classes.formControl}>
                                <Select
                                    disabled={camerasLoading || framesLoading}
                                    labelId="instant-select-label"
                                    id="instant-select"
                                    defaultValue={0}
                                    value={currentInstant}
                                    onChange={onChangeInstant}
                                    input={<CustomSelectInput />}
                                >
                                    <MenuItem value={0} disabled>Seleccionar Instante</MenuItem>
                                    {instants.map((instant, index) => (
                                        <MenuItem key={index} value={index + 1}>{ instant.label }</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.buttonItemRight}>
                            {makingVideo?(
                                <Button
                                    size="small"
                                    variant="contained"
                                    disableElevation
                                    disabled
                                    color="primary"
                                    startIcon={<MovieFilterIcon />}
                                >
                                    Creando Video: {taskData.progress}%
                                </Button>
                            ):(
                                <Button
                                    size="small"
                                    variant="contained"
                                    disableElevation
                                    disabled={camerasLoading || framesLoading || currentInstant == 0 || !framesLoaded}
                                    color="primary"
                                    startIcon={<MovieFilterIcon />}
                                    onClick={handleCreateVideo}
                                >
                                    Crear Video
                                </Button>
                            )}
                        </div>
                    </div>
                    <TimelapseViewer
                        loading={camerasLoading}
                        framesLoading={framesLoading}
                        frames={frames}
                        currentFrame={currentFrame}
                        onFrameChange={handleFrameChange}
                        isInstantSelected={currentInstant != 0}
                    />
                </CardContent>
            </Card>
            {videosLoading?(
                <CardLoading text="Cargando Videos..." />
            ):(
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            Videos:
                        </Typography>
                        <ListVideos
                            videos={videos}
                            onDownload={downloadVideo}
                        />
                    </CardContent>
                </Card>
            )}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Selecciona los Instantes:</DialogTitle>
                <DialogContent>
                    <form className={classes.dialogContainer}>
                        <FormControl className={classes.dialogFormControl}>
                            <InputLabel htmlFor="dialog-start-select-label">Inicio</InputLabel>
                            <Select
                                labelId="dialog-start-select-label"
                                id="dialog-start-select"
                                value={dialogStartDate}
                                onChange={handleDialogStartDateChange}
                                input={<Input />}
                            >
                                {frames.map((frame, index) => (
                                    <MenuItem
                                        key={index}
                                        value={frame.date}
                                        disabled={frame.date >= dialogEndDate}
                                    >
                                        { frame.time } 
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.dialogFormControl}>
                            <InputLabel id="dialog-end-select-label">Término</InputLabel>
                            <Select
                                labelId="dialog-end-select-label"
                                id="dialog-end-select"
                                value={dialogEndDate}
                                onChange={handleDialogEndDateChange}
                                input={<Input />}
                            >
                                {frames.map((frame, index) => (
                                    <MenuItem
                                        key={index}
                                        value={frame.date}
                                        disabled={frame.date <= dialogStartDate}
                                    >
                                        { frame.time }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </section>
    );
}

Timelapse.propTypes = {};

export default Timelapse;