import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';

import VectorRow from '../../../components/Planification/VectorRow.jsx';
import DialogCrearSimulacion from '../../../components/DialogCrearSimulacion/DialogCrearSimulacion.jsx';

import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(2),
	},
	subtitle: {
		color: grey[700],
	},
	toolbar: {
		padding: theme.spacing(2),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: theme.spacing(2),
	},
	buttonTooltip: {
		marginLeft: theme.spacing(1),
	},
	titleId: {
		color: theme.palette.primary.main,
	}
}));

function Detail({ planification }) {
	const classes = useStyles();
	const history = useHistory();
	const [simulating, setSimulating] = React.useState(planification.job_state == 'submitted' || planification.job_state == 'pending' || planification.job_state == 'runnable' || planification.job_state == 'starting' || planification.job_state == 'running');
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleDialogAgree = async () => {
		setDialogOpen(false);
		api.post('simulate', { id: planification.id }).then(() => {
			planification.job_state = 'submitted';
			setSimulating(true);
		}).catch(err => {
			enqueueSnackbar(err.message, { variant: 'error' });
		});
	};

	const handleDialogCancel = () => {
		setDialogOpen(false);
	};

	const handleCreatePlanificacion = () => {
		history.push(`/planification/${planification.id}/create`);
	};

	return (
		<React.Fragment>
			<Card className={classes.root}>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Fecha</TableCell>
								<TableCell>Hora</TableCell>
								<TableCell>
									<Box className={classes.infoIconCell}>
										Carb&oacute;n
										<IconButton size="small" className={classes.infoIcon}>
											<Tooltip title="wi_301">
												<InfoOutlinedIcon size="small" />
											</Tooltip>
										</IconButton>
									</Box>
								</TableCell>
								<TableCell>Petr&oacute;leo</TableCell>
								<TableCell>
									<Box className={classes.infoIconCell}>
										Cal Hidratada
										<IconButton size="small" className={classes.infoIcon}>
											<Tooltip title={
												<React.Fragment>
													Ca(OH)<sub>2</sub>
												</React.Fragment>
											}>
												<InfoOutlinedIcon size="small" />
											</Tooltip>
										</IconButton>
									</Box>
								</TableCell>
								<TableCell>
									<Box className={classes.infoIconCell}>
										Temperatura
										<IconButton size="small" className={classes.infoIcon}>
											<Tooltip title="ti_328">
												<InfoOutlinedIcon size="small" />
											</Tooltip>
										</IconButton>
									</Box>
								</TableCell>
								<TableCell>
									<Box className={classes.infoIconCell}>
										Ritmo
										<IconButton size="small" className={classes.infoIcon}>
											<Tooltip title="Ritmo de alimentaci&oacute;n ('wi_182')">
												<InfoOutlinedIcon size="small" />
											</Tooltip>
										</IconButton>
									</Box>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{planification.vectors.map((vector, index) => {
								if(vector.change){
									return (
										<VectorRow
											key={index}
											lastItem={index == (planification.vectors.length - 1)}
											data={vector.fields}
											hour={vector.text_time}
											date={vector.text_date}
											dateRows={vector.amount}
										/>
									);
								}else{
									return (
										<VectorRow
											key={index}
											lastItem={index == (planification.vectors.length - 1)}
											data={vector.fields}
											hour={vector.text_time}
										/>
									);
								}
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<Box className={classes.buttons}>
					{(planification.job_state == null || simulating)&&(
						<Button
							variant="contained"
							color="primary"
							disableElevation
							startIcon={<VideoLabelIcon />}
							disabled={simulating}
							onClick={() => setDialogOpen(true)}
						>
							{(simulating?'Simulando...':'Generar Pronóstico')}
						</Button>
					)}
					<Tooltip title="Crear una nueva planificaci&oacute;n usando los datos de esta como base." className={classes.buttonTooltip}>
						<Button variant="contained" color="primary" disableElevation startIcon={<AddCircleOutlineIcon />} onClick={handleCreatePlanificacion}>
							Crear Nueva Planificaci&oacute;n
						</Button>
					</Tooltip>
				</Box>
			</Card>
			<DialogCrearSimulacion open={dialogOpen} onAgree={handleDialogAgree} onCancel={handleDialogCancel} />
		</React.Fragment>
	);
}

Detail.propTypes = {
	planification: PropTypes.object.isRequired,
};

export default Detail;