import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CloudOffIcon from '@material-ui/icons/CloudOff';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';

import CardLoading from '../../components/CardLoading/CardLoading.jsx';
import VtkViewer from '../../components/VtkViewer/VtkViewer.jsx';
import HomeEmptyCard from '../../components/Home/HomeEmptyCard.jsx';

import concentracionesValores from '../../utils/concentraciones.js';
import { appendClasses } from '../../utils/index.js';

import useStyles from './Home.styles.js';
import { generateInstants, checkVectores } from './Home.utils.js';
import { useFetch } from '../../hooks/fetch.js';

function ColoredReceptorValue(props)
{
	const classes = useStyles();

	// Get value
	let value = props.receptors.find(recp => (recp.id === props.id)).value;
	let roundedValue = Math.round((value + Number.EPSILON) * 100) / 100;

	// Get correct class (color)
	let colorClass = classes.receptorSuccess;
	if(value > concentracionesValores.mp.bad){
		colorClass = classes.receptorAlert;
	}
	else if(value > concentracionesValores.mp.regular){
		colorClass = classes.receptorWarning;
	}

	// Return component
	return (
		<Typography className={colorClass}>{roundedValue}</Typography>
	);
};
ColoredReceptorValue.propTypes = {
	receptors: PropTypes.array.isRequired,
	id: PropTypes.number.isRequired,
};

function Home() {
	const classes = useStyles();

	const [instantes, setInstantes] = useState(0);
	const [dates, setDates] = useState(null);
	const [datesDays, setDatesDays] = useState(null);
	const [vectores, setVectores] = useState(null);
	const [instateActual, setInstanteActual] = useState(0);
	const [vtkUrls, setVtkUrls] = useState(null);
	const [receptores, setReceptores] = useState(null);
	const [receptoresEspeciales, setScpecialReceptores] = useState(null);
	//const [concentraciones, setConsentraciones] = useState(null);
	const [empty, setEmpty] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const receptors = useFetch('/get-receptors-name', { cache: true });
	const concentrations = useFetch('/concentrations-home', { cache: true });

	const plumeValue = (value) => {
		if(value){
			return (
				<Typography className={classes.receptorWarning}><CloudQueueIcon fontSize="small"/></Typography>
			);
		}
		else{
			return (
				<Typography className={classes.receptorSuccess}><CloudOffIcon fontSize="small"/></Typography>
			);
		}
	};

	const handleValueChange = (newValue) => {
		if(newValue >= 0 && newValue < instantes) setInstanteActual(newValue);
	};

	useEffect(() => {
		if(receptors.status == 'done' && concentrations.status == 'done'){
			// Get receptores
			const recps = receptors.data;

			// Get concentraciones
			const consts = concentrations.data;

			if(consts.length == 0){
				setEmpty(true);
				return () => {};
			}

			if(!checkVectores(consts)){
				setEmpty(true);
				enqueueSnackbar('Aún no hay datos para mostrar!', { variant: 'info' });
				return () => {};
			}

			// Store receptores and concentracione
			setReceptores(recps.filter(r => !r.source));
			setScpecialReceptores(recps.filter(r => r.source));
			//setConsentraciones(consts);

			// Add vtk_url
			let vtk_list = consts.map((vector) => {
				vector.span = new Date(vector.span);
				return {
					plume: vector.route_plume,
					floor: vector.route_floor,
				};
			});

			// Generate dates
			let dates = generateInstants(consts);

			// Store data in component instance
			setInstantes(consts.length);
			setDates(dates);
			setDatesDays(dates.filter(item => item.change));
			setVtkUrls(vtk_list);
			setVectores(consts);
		}
	}, [receptors.status, concentrations.status]);

	if(empty){
		return (
			<section className={classes.root}>
				<HomeEmptyCard />
			</section>
		);
	}
	else if(instantes == 0 || vectores == null){
		return (
			<section className={classes.root}>
				<CardLoading text="Cargando Pron&oacute;stico..." />
			</section>
		);
	}

	return (
		<section className={classes.root}>
			<Card className={classes.viewer}>
				<CardContent>
					<Typography variant="h5" component="h2">Pron&oacute;stico de las Operaciones</Typography>
					<VtkViewer urls={vtkUrls} value={instateActual} receptors={vectores[instateActual].receptors} onChange={handleValueChange} />
				</CardContent>
			</Card>
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							{datesDays.map((item, index) => (
								<TableCell key={index} colSpan={item.amount} className={classes.dateCell}>{item.text_date}</TableCell>
							))}
							<TableCell className={classes.lastCell}></TableCell>
						</TableRow>
						<TableRow>
							<TableCell className={classes.cellAlignVertical}>Receptor</TableCell>
							{dates.map((item, index) => (
								<TableCell key={index} align="center" className={appendClasses(classes.tableHeaderCell, (instateActual === index)?classes.cellSelected:null)}>
									<Typography className={classes.cellVertical} component="span">
										{item.text_time}
									</Typography>
								</TableCell>
							))}
							<TableCell className={classes.lastCell}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{receptoresEspeciales.map((receptor, r_index) => (
							<TableRow key={r_index}>
								<TableCell component="th" scope="row" className={classes.colSticky}>
									<Typography className={classes.receptorName} noWrap>{receptor.label}</Typography>
								</TableCell>
								{vectores.map((vector, index) => (
									<TableCell key={index} align="center" className={appendClasses(classes.tableBodyCell, (instateActual === index)?classes.cellSelected:null)}>
										{plumeValue(vector.receptors.find(recp => (recp.id === receptor.id)).value)}
									</TableCell>
								))}
								<TableCell className={classes.lastCell}></TableCell>
							</TableRow>
						))}
						{receptores.map((receptor, r_index) => (
							<TableRow key={r_index}>
								<TableCell component="th" scope="row" className={classes.colSticky}>
									<Typography className={classes.receptorName} noWrap>{receptor.label}</Typography>
								</TableCell>
								{vectores.map((vector, index) => (
									<TableCell key={index} align="center" className={appendClasses(classes.tableBodyCell, (instateActual === index)?classes.cellSelected:null)}>
										<ColoredReceptorValue receptors={vector.receptors} id={receptor.id} />
									</TableCell>
								))}
								<TableCell className={classes.lastCell}></TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</section>
	);
};

Home.propTypes = {};

export default Home;