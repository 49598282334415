import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

import DialogReSimular from '../DialogReSimular';
import DialogCrearSimulacion from '../DialogCrearSimulacion/DialogCrearSimulacion.jsx';
//import { columns, emitter } from './shared.js';
import { appendClasses } from '../../utils/index.js';
import { useSession } from '../../contexts/SessionContext.js';

const useRowStyles = makeStyles(theme => ({
	rowRoot: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	collapseRow: {
		padding: 0,
	},
	subtable: {
		borderWidth: 1,
		borderColor: grey[300],
		borderStyle: 'solid',
	},
	subtableOpenCell: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		width: theme.spacing(2),
	},
	subtableRow: {
		cursor: 'pointer',
	},
	subtableCell: {
		padding: theme.spacing(1),
		verticalAlign: 'middle',
	},
	checkIconTrue: {
		color: green[500],
	},
	checkIconFalse: {
		color: grey[300],
		'&:hover': {
			color: grey[500],
		},
		'&:active': {
			color: grey[400],
		},
	},
	loadingSimulation: {
		color: grey[500],
	},
	alertsNone: {
		display: 'inline',
		padding: '0.2em 0.5em',
		backgroundColor: grey[200],
		color: 'rgba(0,0,0,.3)',
		borderRadius: '2px',
	},
	alertsOk: {
		display: 'inline',
		padding: '0.2em 0.5em 0.2em 0.2em',
		backgroundColor: green[200],
		color: 'rgba(0,0,0,.6)',
		borderRadius: '2px',
	},
	alertsWarn: {
		display: 'inline',
		padding: '0.2em 0.5em 0.2em 0.2em',
		backgroundColor: yellow[200],
		color: 'rgba(0,0,0,.6)',
		borderRadius: '2px',
	},
	alertsIcon: {
		verticalAlign: 'bottom',
	}
}));

const random = () => Math.round((1 - Math.random()) * 100);

function SubTableRow({ planification, selected, onRedirect, onSelect, onFailed, onSimulate, onForecast }){
	const classes = useRowStyles();

	return (
		<TableRow>
			<TableCell className={appendClasses(classes.subtableCell, (selected)? classes.checkIconTrue: classes.checkIconFalse)}>
				<Box display="flex" alignItems="center" justifyContent="center">
					{(selected)?(
						<CheckCircleOutlineIcon />
					):(
						<RadioButtonUncheckedIcon onClick={() => onSelect(planification.id)}/>
					)}
				</Box>
			</TableCell>
			<TableCell className={classes.subtableCell}>
				<Typography color="primary">#{ planification.id }</Typography>
			</TableCell>
			<TableCell className={classes.subtableCell}>
			{(planification.alerts == null)?(
				<div className={classes.alertsNone}>-</div>
			):(
				(planification.alerts > 0)?(
					<div className={classes.alertsWarn}>
						<WarningIcon fontSize="small" className={classes.alertsIcon} /> {planification.alerts}
					</div>
				):(
					<div className={classes.alertsOk}>
						<CheckIcon fontSize="small" className={classes.alertsIcon} /> 0
					</div>
				)
			)}
			</TableCell>
			<TableCell className={classes.subtableCell}>
				{ (planification.reliability)? Math.round(planification.reliability)+'%': '-' }
			</TableCell>
			<TableCell className={classes.subtableCell}>
				{ planification.start_at }
			</TableCell>
			<TableCell className={classes.subtableCell}>
				{ planification.end_at }
			</TableCell>
			<TableCell className={classes.subtableCell} align="center">
				<Button
					variant="contained"
					size="small"
					color="secondary"
					disableElevation
					onClick={() => onRedirect(planification.id)}
				>
					Detalle
				</Button>
			</TableCell>
			<TableCell className={classes.subtableCell} align="center">
				{(planification.job_state == null || planification.job_state == 'none')?(
					<Button variant="contained" size="small" color="primary" disableElevation onClick={() => onSimulate(planification.id)}>Generar</Button>
				):(planification.job_state == 'submitted' || planification.job_state == 'pending' || planification.job_state == 'runnable' || planification.job_state == 'starting' || planification.job_state == 'running')?(
					<Button variant="contained" size="small" color="primary" disableElevation disabled>Simulando</Button>
				):(planification.job_state == 'succeeded' || planification.job_state == 'finished')?(
					<Button variant="contained" size="small" color="primary" disableElevation onClick={() => onForecast(planification.id)}>Ver</Button>
				):(planification.job_state == 'failed')?(
					<Button variant="contained" size="small" color="primary" disableElevation onClick={() => onFailed(planification.id)}>Error</Button>
				):(
					<Button variant="contained" size="small" color="primary" disableElevation disabled>Unkown</Button>
				)}
			</TableCell>
		</TableRow>
	);
}

SubTableRow.propTypes = {
	planification: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
	onRedirect: PropTypes.func.isRequired,
	onFailed: PropTypes.func.isRequired,
	onSimulate: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	onForecast: PropTypes.func.isRequired,
};

function findPlanification(id, data){
	if(data && data instanceof Array){
		for(let plan of data){
			if(plan.id == id) return plan;
		}
	}
	return null;
}

function ListTableRow({ date, parent, selected, planifications, open, index, onOpen, onReload, onSelect }) {
	const classes = useRowStyles();
	const history = useHistory();
	const { api } = useSession();

	const { enqueueSnackbar } = useSnackbar();

	// Dialog data
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogReOpen, setDialogReOpen] = useState(false);
	const [dialogPlanId, setDialogPlanId] = useState(null);

	const handleDialogAgree = async () => {
		if(dialogPlanId != null){
			setDialogOpen(false);
			setDialogReOpen(false);
			api.post('simulate', { id: dialogPlanId }).then(() => {
				let plan = findPlanification(dialogPlanId, planifications);
				if(!plan) return;
				plan.job_state = 'running';
				onReload();
			}).catch(err => {
				enqueueSnackbar(err.message, { variant: 'error' });
			});
		}
	};

	const handleDialogCancel = () => {
		setDialogOpen(false);
		setDialogReOpen(false);
	};

	const handleSimulate = (id) => {
		if(id){
			setDialogPlanId(id);
			setDialogOpen(true);
		}
	};

	const handleFailed = (id) => {
		if(id){
			setDialogPlanId(id);
			setDialogReOpen(true);
		}
	};

	const handleRedirect = (id) => history.push('/planification/'+id+'/forecast');

	const handleRedirectPlanification = (id) => history.push('/planification/'+id+'/detail');

	return (
		<React.Fragment>
			<TableRow className={classes.rowRoot} onClick={onOpen}>
				<TableCell>
					<IconButton aria-label="expand row" size="small">
						{(open === index)? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell>{date}</TableCell>
				<TableCell>{planifications.length + 1}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell className={classes.collapseRow} colSpan={3}>
					<Collapse in={open === index} timeout="auto" unmountOnExit>
						<Divider />
						<Box margin={3}>
							<Typography variant="h6" gutterBottom component="div">Planificaciones</Typography>
							<Table className={classes.subtable} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell className={classes.subtableCell} width="45"></TableCell>
										<TableCell className={classes.subtableCell}>Id</TableCell>
										<TableCell className={classes.subtableCell}>Alertas</TableCell>
										<TableCell className={classes.subtableCell}>Confiabilidad</TableCell>
										<TableCell className={classes.subtableCell}>Fecha de Inicio</TableCell>
										<TableCell className={classes.subtableCell}>Fecha de Termino</TableCell>
										<TableCell className={classes.subtableCell} width="100" align="center"></TableCell>
										<TableCell className={classes.subtableCell} width="120" align="center">Pron&oacute;stico</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<SubTableRow 
										planification={parent}
										selected={parent.id == selected}
										onRedirect={handleRedirectPlanification}
										onFailed={handleFailed}
										onSimulate={handleSimulate}
										onSelect={onSelect}
										onForecast={handleRedirect}
									/>
									{planifications.map((plan, index) => (
										<SubTableRow
											key={index}
											planification={plan}
											selected={plan.id == selected}
											onRedirect={handleRedirectPlanification}
											onFailed={handleFailed}
											onSimulate={handleSimulate}
											onSelect={onSelect}
											onForecast={handleRedirect}
										/>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
			<DialogCrearSimulacion open={dialogOpen} onAgree={handleDialogAgree} onCancel={handleDialogCancel} />
			<DialogReSimular open={dialogReOpen} onAgree={handleDialogAgree} onCancel={handleDialogCancel} />
		</React.Fragment>
	);
}

ListTableRow.propTypes = {
	date: PropTypes.string.isRequired,
	parent: PropTypes.object.isRequired,
	planifications: PropTypes.array.isRequired,
	selected: PropTypes.number,
	open: PropTypes.any,
	index: PropTypes.any.isRequired,
	onReload: PropTypes.func.isRequired,
	onOpen: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default ListTableRow;